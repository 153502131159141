// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KeycloakConfig } from 'keycloak-js';

let keycloakConfig: KeycloakConfig = {
  clientId: window['env']['keycloak_clientId'],
  realm: window['env']['keycloak_realm'],
  url: window['env']['keycloak_url'],
};

export const environment = {
  production: window['env']['production'] || false,
  appVersion: window['env']['appVersion'],
  USERDATA_KEY: window['env']['USERDATA_KEY'],
  keycloak: keycloakConfig,
  isMockEnabled: false,
  apiUrl: window['env']['apiUrl'],
  apiProc: window['env']['apiProc'],
  apiUrlSocket: window['env']['apiUrlSocket'],
  version: 'v0.213',
  //apiUrlSocket: 'http://localhost:4555'

};
